* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --cream-color: #fffffc;
  --light-color: #e6dcd1;
  --dark-color: #2e2d2c;

  --default-transition: all cubic-bezier(0, 0.01, 0.01, 1) 0.3s;
  --slow-transition: all cubic-bezier(0, 0.01, 0.01, 1) 0.5s;
}

/* =====|  Typography  |===== */

@font-face {
  font-family: "Inter";
  font-weight: 300;
  src: url(./Assets/Fonts/Inter/Inter-Light.ttf);
}

@font-face {
  font-family: "Inter";
  font-weight: 400;
  src: url(./Assets/Fonts/Inter/Inter-Regular.ttf);
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  src: url(./Assets/Fonts/Inter/Inter-SemiBold.ttf);
}

@font-face {
  font-family: "Inter";
  font-weight: 600;
  src: url(./Assets/Fonts/Inter/Inter-Bold.ttf);
}

@font-face {
  font-family: "Balarm";
  font-weight: 400;
  src: url(./Assets/Fonts/Balarm/Balarm.ttf);
}

@font-face {
  font-family: "Balarm";
  font-weight: 500;
  src: url(./Assets/Fonts/Balarm/Balarm-Bold.ttf);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: var(--light-color);
  font-family: "Inter", sans-serif !important;
}

h1 {
  font-size: 32px;
  font-weight: 500;
  font-family: "Balarm", sans-serif !important;
}

h2 {
  font-size: 28px;
  font-weight: 600;
}

h3 {
  font-size: 24px;
  font-weight: 600;
}

h4 {
  font-size: 20px;
  font-weight: 600;
}

h5 {
  font-size: 18px;
  font-weight: 600;
}

h6 {
  font-size: 14px;
  font-weight: 600;
}

p {
  font-size: 14px;
  font-weight: 400;
}

.primary-font {
  font-family: "Balarm", sans-serif !important;
}

.secondary-font {
  font-family: "Inter", sans-serif !important;
}

.light-color-font {
  color: var(--light-color);
}

.dark-color-font {
  color: var(--dark-color);
}

::selection {
  background-color: #e6dcd150;
}

/* =====|  Common Components  |===== */

.pointer {
  cursor: pointer;
}

.heading-overflow-hidden {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading-overflow-hidden h1 {
  /* font-size: calc(80px + (200 - 14) * ((100vw - 300px) / (1600 - 300))); */
  font-size: calc(10px + (150 - 14) * ((100vw - 300px) / (1600 - 300)));
  line-height: 0.9;
  font-weight: 800;
  animation: 0.7s slide-up 0.1s cubic-bezier(0, 0.01, 0.01, 1) both;
  /* color: var(--light-color); */
  letter-spacing: 2px;
  transition: all cubic-bezier(0, 0.01, 0.01, 1) 0.3s;
}

@keyframes slide-up {
  from {
    transform: translateY(150%) rotate(10deg);
  }
  to {
    transform: translateY(0%) rotate(0deg);
  }
}

.custom-link {
  display: flex;
  flex-direction: column;
  transition: var(--slow-transition);

  & .link-line {
    height: 2px;
    width: 0;
    background-color: var(--light-color);
    transition: var(--slow-transition);
  }
  &:hover .link-line {
    width: 100%;
  }
}

/* =====|  Header  |===== */

.header {
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 50px;
  margin-inline: auto;
  display: flex;
  width: 100vw;
  overflow: hidden;
  z-index: 6;

  &.construction-header {
    justify-content: space-between;
    padding-inline: 24px;
    top: 24px;
  }

  & > img {
    width: 140px;
    /* animation: 0.7s slide-up 0.1s cubic-bezier(0, 0.01, 0.01, 1) both; */
    animation: 0.75s fade-in-up 0.05s cubic-bezier(0, 0.01, 0.01, 1) both;
  }
}

.menu-btn {
  width: 150px;
  height: 50px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  transition: var(--default-transition);
  transition-duration: 0.5s;
  border: 1px solid var(--light-color);
  color: var(--light-color);
  background-color: transparent;
  font-weight: 500;

  &:hover,
  &.active {
    border: 1px solid var(--dark-color);
    color: var(--dark-color);
    background-color: var(--light-color);
  }

  &.active:hover {
    border: 1px solid var(--light-color);
    color: var(--light-color);
    background-color: transparent;
  }
}

.burger-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  height: 70px;
  width: 70px;
  cursor: pointer;
  animation: 0.75s fade-in-up 0.2s cubic-bezier(0, 0.01, 0.01, 1) both;
}

.burger-block {
  width: 32px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  transition: all cubic-bezier(0, 0.01, 0.01, 1) 0.3s;
}

.burger-upper-line,
.burger-middle-line,
.burger-bottom-line {
  width: 38px;
  height: 2px;
  background-color: var(--light-color);
  transition: all cubic-bezier(0, 0.01, 0.01, 1) 0.3s;
}

.burger-block-crossed {
  justify-content: center;
}

.burger-block-crossed .burger-upper-line {
  transform: rotate(45deg);
  background-color: var(--cream-color);
}

.burger-block-crossed .burger-middle-line {
  width: 0;
}

.burger-block-crossed .burger-bottom-line {
  transform: rotate(135deg);
  background-color: var(--cream-color);
  margin-top: -4px;
}

.burger-menu:hover .burger-upper-line {
  background-color: var(--cream-color);
}

.burger-menu:hover .burger-bottom-line {
  background-color: var(--cream-color);
}

@media (max-width: 468px) {
  .header {
    top: 30px;

    & > img {
      width: 110px;
    }
  }

  .menu-btn {
    width: 80px;
    height: 40px;
    font-size: 14px;
  }
}

/* =====|  Menu  |===== */

.menu-container {
  width: 100vw;
  height: 0vh;
  background-color: var(--dark-color);
  position: fixed;
  z-index: 5;
  transition: all cubic-bezier(0, 0.01, 0.01, 1) 0.45s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 60px;

  /* webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px); */
  /* background-color: rgba(0, 0, 0, 0.7); */

  /* -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: transparent; */

  &.open {
    padding: 180px 220px;
    height: 100vh;
  }

  & .menu-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;

    & h1 {
      font-size: 82px;
      font-weight: 400;
    }
    /* & > div {
      justify-content: flex-start;
      cursor: pointer;

      & h1 {
        font-size: 82px;
        font-weight: 400;
        transition: all cubic-bezier(0, 0.01, 0.01, 1) 0.3s;
      }
    }
    & > div:hover {
      & h1 {
        letter-spacing: 4%;
      }
    } */
  }
}

.social-icons {
  position: fixed;
  bottom: 24px;
  right: 24px;
  color: var(--light-color);
  z-index: 6;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;

  & > * {
    text-decoration: none;
    color: inherit;
    font-size: 32px;
    cursor: pointer;

    & svg {
      width: 32px;
      height: 32px;
      & path {
        fill: var(--light-color);
      }
    }
    /* & a { */
    display: flex;
    align-items: center;
    justify-content: center;
    /* } */

    animation: 1s fade-in-up 0.25s cubic-bezier(0, 0.01, 0.01, 1) both;
    &:nth-child(2) {
      animation: 1s fade-in-up 0.3s cubic-bezier(0, 0.01, 0.01, 1) both;
    }
  }
}

@media (max-width: 968px) {
  .menu-container {
    &.open {
      padding: 120px 24px;
    }

    & .menu-items {
      gap: 20px;

      & h1 {
        font-size: 32px;
      }

      /* & > div {
        & h1 {
          font-size: 32px;
        }
      }
      & > div:hover {
        & h1 {
          letter-spacing: 12%;
        }
      } */
    }
  }
}

/* =====|  Contact About  |===== */

.contact-page {
  width: 100vw;
  min-height: 100vh;
  background-color: var(--dark-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  gap: 30px;
  padding: 20px 280px;

  & form {
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 100%;
    max-width: 600px;

    & .two-cols {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: inherit;
    }

    & input,
    & textarea,
    & button {
      padding: 12px 18px;
      background-color: var(--dark-color);
      border: 1px solid var(--light-color);
      color: var(--light-color);
      outline: none;
      font-weight: 400;
      resize: none;
      animation: 1s fade-in-up 0.1s cubic-bezier(0, 0.01, 0.01, 1) both;
      min-width: 9px;
      border-radius: 0px;
    }

    & button {
      transition: var(--default-transition);
    }

    & button:hover {
      background-color: var(--light-color);
      color: var(--dark-color);
    }
  }

  & h1 {
    font-size: 64px;
    font-weight: 400;
  }
  & p {
    max-width: 600px;
    line-height: 1.6;

    animation: 1s fade-in-up 0.1s cubic-bezier(0, 0.01, 0.01, 1) both;
  }
}

/* @media (min-width: 468px) and (max-width: 768px) { */
@media (max-width: 768px) {
  .contact-page {
    gap: 30px;
    padding: 20px 24px;

    & h1 {
      font-size: 52px;
      font-weight: 400;
    }
    & p {
      max-width: 600px;
      line-height: 1.6;
    }
  }
}

@media (max-width: 468px) {
  .contact-page {
    & form {
      & .two-cols {
        display: grid;
        grid-template-columns: 1fr;
        gap: inherit;

        & > div:nth-of-type(1) {
          display: none;
        }
      }
    }
  }
}

/* =====|  Construction About  |===== */

.about-page {
  width: 100vw;
  min-height: 100vh;
  background-color: var(--dark-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  gap: 30px;
  padding: 120px 280px;

  & h1 {
    font-size: 64px;
    font-weight: 400;
  }
  & p {
    max-width: 600px;
    line-height: 1.6;

    animation: 1s fade-in-up 0.1s cubic-bezier(0, 0.01, 0.01, 1) both;
  }
}

@media (max-width: 768px) {
  .about-page {
    gap: 30px;
    padding: 160px 24px;

    & h1 {
      font-size: 52px;
      font-weight: 400;
    }
    & p {
      max-width: 600px;
      line-height: 1.6;
    }
  }
}

@keyframes fade-in-up {
  0% {
    transform: translateY(60px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

/* =====|  Landing Page  |===== */

.landing-page {
  width: 100vw;
  min-height: 100vh;
  background-color: var(--light-color);
  position: relative;
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;

  & .landing-page-content {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1px 1fr;
  }
}

.landing-page .y-line {
  height: 8vh;
  width: 2px;
  border-radius: 4px;
  background-color: var(--dark-color);
  margin-inline: auto;
  animation: 1.2s grow-up 0.3s cubic-bezier(0, 0.01, 0.01, 1) both;
}

.landing-page .landing-page-btns {
  display: inline-flex;
  cursor: pointer;
}

.landing-page-images {
  cursor: pointer;
  overflow: hidden;
  display: flex;
  justify-content: center;
  transition: all ease-in-out 0.3s;

  & img {
    width: 290px;
    animation: 0.7s slide-up-long 0.3s cubic-bezier(0, 0.01, 0.01, 1) both;
  }

  &:hover {
    transform: scale(1.05);
  }
}

.landing-page .landing-page-btns:hover > * {
  letter-spacing: 5.5px;
}

.landing-page-footer {
  position: absolute;
  bottom: 30px;
  margin-inline: auto;
  display: flex;
  justify-content: center;
  width: 100vw;
  overflow: hidden;

  color: var(--light-color);

  &.light-mode {
    color: var(--dark-color);
  }

  & > p {
    color: inherit;
    font-size: 12px;
    animation: 0.7s slide-up-long 0.4s cubic-bezier(0, 0.01, 0.01, 1) both;
  }
}

@media (max-width: 768px) {
  .landing-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 120px;

    & .landing-page-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 30px;
    }
  }

  .landing-page .y-line {
    height: 2px;
    width: 35vw;
  }

  .landing-page-images {
    & img {
      width: 150px;
    }
  }
}

@keyframes grow-up {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}

@keyframes slide-up-long {
  from {
    transform: translateY(180%) rotate(4deg);
  }
  to {
    transform: translateY(0%) rotate(0deg);
  }
}

.back-to-home-btn {
  background-color: transparent;
  color: var(--light-color);
  border: 1px solid var(--light-color);
  padding: 12px 24px;
  border-radius: 34px;
  margin-top: 38px;
  cursor: pointer;
  transition: var(--default-transition);
  animation: 0.75s fade-in-up 0.4s cubic-bezier(0, 0.01, 0.01, 1) both;
  width: max-content;
  margin-inline: auto;

  &:hover {
    background-color: var(--light-color);
    color: var(--dark-color);
  }
}

/* =====|  Laboratory Page  |===== */

.laboratory-page {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--dark-color);
  position: relative;
  gap: 160px;
}

& .logo-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

/* =====|  Home Page  |===== */

.hero {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--dark-color);
  position: relative;
  overflow: hidden;
}

.hero * {
  z-index: 2;
}

.hero-img {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1;
  object-fit: cover;
  animation: 1.4s grow-out 0.1s cubic-bezier(0, 0.01, 0.01, 1) both;
  filter: brightness(0.65);
}

.hero-sub-headings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 24px;
  padding-inline: 24px;
  gap: 18px;
  position: absolute;
  width: 100vw;
}

@media (max-width: 768px) {
  .hero-sub-headings {
    flex-direction: column;
  }
}

@keyframes grow-out {
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}


@supports (height: 100svh) {
  /* #Aktuell {
    height: 100svh;
  } */

  .menu-container {
    &.open {
      height: 100svh;
    }
  }

  .hero,
  .hero-img {
    height: 100svh;
  }

  .landing-page,
  .laboratory-page,
  .about-page,
  .contact-page {
    min-height: 100svh;
  }
}